<template>
  <v-container fluid>
    <head-title text="Transacciones Internas" :to="{ name: 'Home' }"></head-title>
    <v-row>
      <v-col cols="12" md="2">
        <company-field
          :textLabel="'Sucursal Origen'"
          v-model="companyId"
          @value="(val) => (companyId = val)"
        ></company-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-select
          :label="'Tipo'"
          :items="[
            { text: 'Cobros', value: 1 },
            { text: 'Pagos', value: 2 },
          ]"
          v-model="type"
        ></v-select>
      </v-col>
      <v-col cols="12" md="1">
        <v-select
          :items="[
            { text: 'Por Mes', value: 1 },
            { text: 'Por Período', value: 2 },
          ]"
          label="Mostrar Datos"
          v-model="searchType"
        ></v-select>
      </v-col>
      <v-col cols="12" md="2" v-if="searchType === 1">
        <date-field label="Mes" v-model="date" type="month"></date-field>
      </v-col>
      <v-col cols="12" md="2" v-if="searchType === 2">
        <date-field label="De" v-model="date"></date-field>
      </v-col>
      <v-col cols="12" md="2" v-if="searchType === 2">
        <date-field label="Hasta" v-model="until"></date-field>
      </v-col>
      <v-col cols="12" md="2">
        <internal-account-field
          v-model="accountInternal"
          :sourceCompany="companyId"
        ></internal-account-field>
      </v-col>
      <v-col cols="12" md="1" class="text-left">
        <v-btn class="mx-1" fab dark small color="indigo" @click="search()">
          <v-icon dark>mdi-database-search</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" md="1" class="text-right">
        <div class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mx-2" fab dark small color="indigo" v-on="on" v-bind="attrs">
                <v-icon>mdi-plus</v-icon></v-btn
              >
            </template>
            <v-list>
              <v-list-item @click="showBillingForm = true" link>
                <v-list-item-title>Nuevo Cobro</v-list-item-title>
              </v-list-item>
              <v-list-item @click="showPaymentForm = true" link>
                <v-list-item-title>Nuevo Pago</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>
      <v-col cols="12" md="1" class="text-left">
        <v-btn
          class="mx-1"
          fab
          dark
          small
          color="indigo"
          @click="showSummaryDealing = true"
        >
          <v-icon dark>mdi-file-pdf-box</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :search="filter"
      hide-default-footer
      disable-pagination
      class="elevation-1"
    >
      <template v-slot:top v-if="items.length > 0">
        <v-text-field
          v-model="filter"
          append-icon="mdi-magnify"
          label="Buscar"
          clearable
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:[`item.code`]="{ item }">
        <v-btn
          v-if="type == 1"
          text
          color="primary"
          @click="displayDetailBilling(item.id)"
          >{{ item.code }}</v-btn
        >
        <v-btn
          v-else-if="type == 2"
          text
          color="primary"
          @click="displayDetailPayment(item.id)"
          >{{ item.code }}</v-btn
        >
      </template>
      <template v-slot:[`item.created`]="{ item }">
        <span>{{ item.created | date }}</span>
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        <span>{{ item.amount | currency }}</span>
      </template>
    </v-data-table>
    <v-pagination
      v-model="currentPage"
      :total-visible="10"
      :length="totalPages"
      :disabled="loading"
      v-show="totalPages > 0"
    ></v-pagination>
    <billing-form
      v-if="showBillingForm"
      @close="(showBillingForm = false), search()"
      @update="search()"
      :dialog="showBillingForm"
    ></billing-form>
    <payment-form
      v-if="showPaymentForm"
      @close="(showPaymentForm = false), search()"
      @update="search()"
      :dialog="showPaymentForm"
    >
    </payment-form>
    <detail-billing
      v-if="showDetailBilling"
      @close="(showDetailBilling = false), search()"
      :dialog="showDetailBilling"
      :billingId="billingid"
    ></detail-billing>
    <detail-payment
      v-if="showDetailPayment"
      @close="(showDetailPayment = false), search()"
      :dialog="showDetailPayment"
      :paymentId="paymentid"
    ></detail-payment>
    <summary-dealing
      v-if="showSummaryDealing"
      @close="(showSummaryDealing = false), search()"
      :dialog="showSummaryDealing"
    ></summary-dealing>
  </v-container>
</template>
<script>
import requests from "@/services/requests";
export default {
  components: {
    HeadTitle: () => import("@/components/base/HeaderTitle"),
    DateField: () => import("@/components/fields/DateField.vue"),
    InternalAccountField: () => import("@/components/fields/AccountInternalField.vue"),
    BillingForm: () => import("@/components/dealings/BillingForm.vue"),
    PaymentForm: () => import("@/components/dealings/PaymentForm.vue"),
    CompanyField: () => import("@/components/fields/CompanyField.vue"),
    DetailBilling: () => import("@/components/dealings/DetailBilling.vue"),
    DetailPayment: () => import("@/components/dealings/DetailPayment.vue"),
    summaryDealing: () => import("@/components/dealings/SummaryDealings.vue"),
  },
  data: () => ({
    companyId: 0,
    showSummaryDealing: false,
    showBillingForm: false,
    showPaymentForm: false,
    showDetailBilling: false,
    showDetailPayment: false,
    currentPage: 1,
    totalPages: 0,
    accountInternal: {},
    billingid: 0,
    paymentid: 0,
    type: 1,
    date: "",
    until: "",
    searchType: null,
    items: [],
    loading: false,
    filter: "",
    headers: [
      { text: "Código", value: "code" },
      { text: "Fecha de Cobro", value: "created" },
      { text: "Origen", value: "internal_account.source_company.second_name" },
      { text: "Destino", value: "internal_account.destination_company.second_name" },
      { text: "Monto", value: "amount" },
    ],
  }),
  mounted() {
    this.searchType = 1;
  },
  methods: {
    displayDetailBilling(id) {
      this.showDetailBilling = true;
      this.billingid = id;
    },
    displayDetailPayment(id) {
      this.showDetailPayment = true;
      this.paymentid = id;
    },
    getList(uri) {
      this.loading = true;
      uri += "&page=" + this.currentPage;
      requests.get(uri).then((response) => {
        if (response.status == 200) {
          this.items = response.data.results;
          this.totalPages = response.data.total_pages;
        }
        this.loading = false;
        this.uri = "";
      });
    },
    search() {
      if (this.date === null) {
        this.$toasted.global.info({
          message: "Seleccione la fecha",
        });
        return;
      }
      if (this.companyId === 0) {
        this.$toasted.global.info({
          message: "Seleccione la sucursal",
        });
        return;
      }

      if (this.type === 1) {
        this.uri =
          "/api/bussiness-connector/internal-billing/?internal_account__source_company=" +
          this.companyId;
      } else if (this.type === 2) {
        this.uri =
          "/api/bussiness-connector/internal-payment/?internal_account__source_company=" +
          this.companyId;
      }

      if (this.searchType === 1) {
        this.uri +=
          "&year=" + this.date.substring(0, 4) + "&month=" + this.date.substring(5, 7);
      } else if (this.searchType === 2 || this.searchType === 3) {
        if (this.until === null) {
          this.$toasted.global.info({
            message: "Seleccione la fecha",
          });
          return;
        } else {
          this.uri += "&date=" + this.date + "&until=" + this.until;
          if (this.searchType === 3) {
            this.uri += "&period=true";
          }
        }
      }
      if (this.accountInternal !== undefined && this.accountInternal.id !== undefined) {
        this.uri += "&internal_account_id=" + this.accountInternal.id;
      }
      this.getList(this.uri);
    },
    newDetail() {},
  },
  watch: {
    companyId(val) {
      if (typeof val === "object") {
        this.companyId = this.companyId.id;
      }
    },
    searchType(newValue) {
      // mes
      if (newValue === 1) {
        this.date = new Date().toISOString().substring(0, 7);
      }
      // periodo
      if (newValue === 2) {
        this.date = new Date().toISOString().substring(0, 10);
        this.until = new Date().toISOString().substring(0, 10);
      }
    },
    currentPage() {
      this.getList(this.uri);
    },
  },
};
</script>
