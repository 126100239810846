var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('head-title',{attrs:{"text":"Transacciones Internas","to":{ name: 'Home' }}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('company-field',{attrs:{"textLabel":'Sucursal Origen'},on:{"value":function (val) { return (_vm.companyId = val); }},model:{value:(_vm.companyId),callback:function ($$v) {_vm.companyId=$$v},expression:"companyId"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"label":'Tipo',"items":[
          { text: 'Cobros', value: 1 },
          { text: 'Pagos', value: 2 } ]},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-select',{attrs:{"items":[
          { text: 'Por Mes', value: 1 },
          { text: 'Por Período', value: 2 } ],"label":"Mostrar Datos"},model:{value:(_vm.searchType),callback:function ($$v) {_vm.searchType=$$v},expression:"searchType"}})],1),(_vm.searchType === 1)?_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('date-field',{attrs:{"label":"Mes","type":"month"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1):_vm._e(),(_vm.searchType === 2)?_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('date-field',{attrs:{"label":"De"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1):_vm._e(),(_vm.searchType === 2)?_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('date-field',{attrs:{"label":"Hasta"},model:{value:(_vm.until),callback:function ($$v) {_vm.until=$$v},expression:"until"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('internal-account-field',{attrs:{"sourceCompany":_vm.companyId},model:{value:(_vm.accountInternal),callback:function ($$v) {_vm.accountInternal=$$v},expression:"accountInternal"}})],1),_c('v-col',{staticClass:"text-left",attrs:{"cols":"12","md":"1"}},[_c('v-btn',{staticClass:"mx-1",attrs:{"fab":"","dark":"","small":"","color":"indigo"},on:{"click":function($event){return _vm.search()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-database-search")])],1)],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"1"}},[_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"indigo"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.showBillingForm = true}}},[_c('v-list-item-title',[_vm._v("Nuevo Cobro")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.showPaymentForm = true}}},[_c('v-list-item-title',[_vm._v("Nuevo Pago")])],1)],1)],1)],1)]),_c('v-col',{staticClass:"text-left",attrs:{"cols":"12","md":"1"}},[_c('v-btn',{staticClass:"mx-1",attrs:{"fab":"","dark":"","small":"","color":"indigo"},on:{"click":function($event){_vm.showSummaryDealing = true}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-file-pdf-box")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"search":_vm.filter,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([(_vm.items.length > 0)?{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"append-icon":"mdi-magnify","label":"Buscar","clearable":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})]},proxy:true}:null,{key:"item.code",fn:function(ref){
        var item = ref.item;
return [(_vm.type == 1)?_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.displayDetailBilling(item.id)}}},[_vm._v(_vm._s(item.code))]):(_vm.type == 2)?_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.displayDetailPayment(item.id)}}},[_vm._v(_vm._s(item.code))]):_vm._e()]}},{key:"item.created",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("date")(item.created)))])]}},{key:"item.amount",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.amount)))])]}}],null,true)}),_c('v-pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.totalPages > 0),expression:"totalPages > 0"}],attrs:{"total-visible":10,"length":_vm.totalPages,"disabled":_vm.loading},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),(_vm.showBillingForm)?_c('billing-form',{attrs:{"dialog":_vm.showBillingForm},on:{"close":function($event){(_vm.showBillingForm = false), _vm.search()},"update":function($event){return _vm.search()}}}):_vm._e(),(_vm.showPaymentForm)?_c('payment-form',{attrs:{"dialog":_vm.showPaymentForm},on:{"close":function($event){(_vm.showPaymentForm = false), _vm.search()},"update":function($event){return _vm.search()}}}):_vm._e(),(_vm.showDetailBilling)?_c('detail-billing',{attrs:{"dialog":_vm.showDetailBilling,"billingId":_vm.billingid},on:{"close":function($event){(_vm.showDetailBilling = false), _vm.search()}}}):_vm._e(),(_vm.showDetailPayment)?_c('detail-payment',{attrs:{"dialog":_vm.showDetailPayment,"paymentId":_vm.paymentid},on:{"close":function($event){(_vm.showDetailPayment = false), _vm.search()}}}):_vm._e(),(_vm.showSummaryDealing)?_c('summary-dealing',{attrs:{"dialog":_vm.showSummaryDealing},on:{"close":function($event){(_vm.showSummaryDealing = false), _vm.search()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }